import React from "react"
import slugify from "slugify"
import { graphql } from "gatsby"
import {
  Layout,
  HeadingCard,
  NavigationCard,
  SpeakerCard,
} from "../../components"
import { allChallenges } from "../../queries/challengeGraphql"
import { useDefaultImage } from "../../queries/defaultImageGraphql"
import * as S from "./challenge.styles"

const Challenge = (data) => {
  const challenge = data.data.contentfulChallenge
  console.log("challenge", challenge)
  const { fluid } = useDefaultImage()
  const { nodes } = allChallenges()

  return (
    <Layout
      title={challenge.title}
      description={challenge.metaDescription}
      image={challenge.primaryImage?.fluid.src || fluid.src}
    >
      <S.Grid>
        <HeadingCard
          subHeading={`${challenge.year} Challenge`}
          heading={challenge.themeName}
          description={challenge.pageDescription?.pageDescription}
        />
        <S.BannerExt image={challenge?.primaryImage} />
        {challenge.quote && (
          <S.Quote width="560px" text={challenge.quote.quote} />
        )}
        <S.BodyOfText text={challenge.body?.json} />
        {challenge.winners && (
          <S.Section>
            <S.SectionTitle>Winners</S.SectionTitle>
            {challenge.winners.map((winner, i) => {
              return (
                <SpeakerCard
                  key={i}
                  name={winner.name}
                  desc={winner.description?.description}
                  img={winner.image}
                  link={winner.link}
                />
              )
            })}
          </S.Section>
        )}
        {challenge.keyDates &&
          challenge.keyDates
            .slice(0, 3)
            .map((keydate, i) => (
              <S.EventCardExt
                key={i}
                title={keydate.title}
                date={keydate.date}
                lowerTheTag
              />
            ))}
        {challenge.keyDates && (
          <S.ProgramCard title="Program Calendar" link="/challenge-calendar" />
        )}
        {challenge.briefs &&
          challenge.briefs.map((brief, i) => {
            const options = {
              lower: true,
              remove: /[*+~.()'"!:@]/g,
            }
            return (
              <S.BriefCard
                key={i}
                title={brief.title}
                heading={brief.categoryName}
                desc={brief.description.description}
                link={`/challenge-${challenge.year}/${slugify(
                  challenge.themeName,
                  options
                )}-${slugify(brief.categoryName, options)}`}
              />
            )
          })}
        {challenge.briefs && <S.Image image={challenge.briefsImage} />}
        <S.ApplicationSection>
          <S.SectionTitle>Application criteria</S.SectionTitle>
          <S.ApplicationText text={challenge.applicationCriteria?.json} />
        </S.ApplicationSection>
        {nodes.length > 1 &&
          nodes.map(
            (oneYear, i) =>
              challenge?.slug !== oneYear.slug && (
                <NavigationCard
                  key={i}
                  heading={`${oneYear.year} Challenge`}
                  title={oneYear.themeName}
                  link={`/challenge-${oneYear.year}/${oneYear.slug}`}
                />
              )
          )}
      </S.Grid>
    </Layout>
  )
}

export default Challenge

export const pageQuery = graphql`
  query ChallengeBySlug($slug: String!) {
    contentfulChallenge(slug: { eq: $slug }) {
      slug
      year
      themeName
      metaDescription
      pageDescription {
        pageDescription
      }
      primaryImage {
        description
        fluid(maxWidth: 1312, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      quote {
        quote
      }
      body {
        json
      }
      applicationCriteria {
        json
      }
      briefs {
        title
        categoryName
        description {
          description
        }
      }
      briefsImage {
        description
        fluid(maxHeight: 656, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      winners {
        name
        description {
          description
        }
        link
        image {
          description
          fluid(maxWidth: 735, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      keyDates {
        title
        date
      }
    }
  }
`
