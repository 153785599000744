import media from "styled-media-query"
import styled from "styled-components"
import {
  HeadingCard,
  Banner,
  BlockOfText,
  EventCard,
  EventCardWLink,
  FormatRichText,
  NavigationCard,
  SpeakerCard,
} from "../../components"

// medium is 768px
// 1055px is the minmium container width on designs

export const SectionTitle = styled.h1``
export const Section = styled.div``
export const BriefCard = styled(EventCardWLink)``
export const Image = styled(Banner)``
export const BannerExt = styled(Banner)``
export const Quote = styled(BlockOfText)``
export const BodyOfText = styled(FormatRichText)``
export const ApplicationText = styled(FormatRichText)``

export const ProgramCard = styled(EventCardWLink)`
  color: ${(props) => props.theme.text04};
  a {
    background-color: ${(props) => props.theme.ui02};
    &:hover {
      background-color: ${(props) => props.theme.hoverSecondary};
    }
  }
  svg {
    fill: ${(props) => props.theme.ui03};
  }
`
export const EventCardExt = styled(EventCard)`
  background: ${(props) => props.theme.ui03};
`
export const ApplicationSection = styled.div`
  background: ${(props) => props.theme.ui03};
  border-top: ${(props) => props.theme.borderStyle};
`

export const Grid = styled.div`
  display: grid;
  ${HeadingCard}, ${BodyOfText}, ${Quote}, ${EventCardExt}, ${ProgramCard}, ${BriefCard}, ${Image} {
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${BannerExt} {
    height: 320px;
    img {
      border-bottom: ${(props) => props.theme.borderStyle};
    }
  }
  ${Image} {
    height: 100%;
    min-height: 320px;
  }
  ${Quote} {
    background: ${(props) => props.theme.ui03};
  }
  ${EventCardExt}, ${ProgramCard} {
    h2 {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    h1 {
      padding-top: 1rem;
    }
  }
  ${ProgramCard}, ${EventCardExt} {
    a,
    div:first-of-type {
      padding: 1rem;
    }
  }
  ${BodyOfText} {
    padding: 1.5rem 1rem 2.5rem 1rem;
    text-align: center;
    p,
    ul {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${HeadingCard} {
    background: ${(props) => props.theme.ui01};
  }
  ${HeadingCard}, ${Quote} {
    padding: 2.5rem 1rem;
  }
  ${BriefCard} {
    a {
      padding: 1.5rem 1rem 1rem 1rem;
    }
    h2 {
      padding-top: 1rem;
    }
  }
  ${SectionTitle} {
    family-font: ${(props) => props.theme.fontStyleTertiary};
    color: ${(props) => props.theme.text02};
    font-size: 0.75rem;
    line-height: 0.9rem;
    padding: 1.5rem 1rem 1rem 1rem;
  }
  ${ApplicationText} {
    padding: 0rem 1rem 0.5rem 1rem;
    align-items: start;
  }
  ${SpeakerCard} {
    padding: 1rem 1rem 2rem 1rem;
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${NavigationCard} {
    border-top: ${(props) => props.theme.borderStyle};
    h2 {
      margin-bottom: 2.5rem;
    }
    a {
      padding: 1.5rem 1rem 1rem 1rem;
    }
  }
  ${media.greaterThan("415px")`
    ${BriefCard} {
      h2, p {
        max-width: 75%;
      }
    }
    ${HeadingCard} {
      > * {
        max-width: 70%;
      }
    }
  `}
  ${media.between("medium", "1055px")`
      ${BriefCard} {
        h2, p {
          max-width: max-content;
        }
      }
  `}
  ${media.greaterThan("medium")`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 320px;
    grid-auto-rows: auto;
    ${ProgramCard}, ${EventCardExt} {
      border-right: ${(props) => props.theme.borderStyle};
      border-bottom: none;
    }
    ${Section} {
      border-bottom: ${(props) => props.theme.borderStyle};
    }
    ${ProgramCard}, ${EventCardExt}  {
        a, div:first-of-type {
          padding: 1rem 2rem;
        }
    }
    ${BriefCard} {
      grid-column: span 2;
      border-bottom: none;
      a {
        padding: 2rem;
        border-top: ${(props) => props.theme.borderStyle};
        &:nth-child(odd) {
          border-right: ${(props) => props.theme.borderStyle};
        }
      }
    }
    ${BodyOfText} {
      padding: 2rem 0rem 5rem 0rem;
      grid-column: span 4;
      > * {
        max-width: 45.45%;
      }
    }
    ${HeadingCard} {
      padding: 4rem 0rem 5rem 0rem;
      grid-column: span 4;
      > * {
        max-width: 38%;
      }
    }
    ${Image} {
      grid-column: 3 / span 2;
      border-top: ${(props) => props.theme.borderStyle};
      border-bottom: none;
    }
    ${BannerExt} {
      grid-column: span 4;
    }
    ${Quote} {
      grid-column: span 4;
      padding: 3rem 0rem 4rem 0rem;
    }

    ${Section}, ${ApplicationSection} {
      grid-column: span 4;
      display: grid;
      grid-template-columns: 25.8% 1fr;
      // grid-auto-rows: auto;
      // grid-template-columns: repeat(4, 1fr);
      padding: 32px;
      padding-right: 0rem;
    }
    ${SectionTitle} {
      grid-column: 1 / span 1;
      padding: 0rem;
    }
    ${ApplicationText} {
      grid-column: 2 / end;
      display: flex;
      align-items: start;
      padding: 0rem;
      > * {
        max-width: 62.5%;
      }
    }
    ${NavigationCard} {
      grid-column: span 4;
      svg {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
        align-self: center;
      }
      h1 {
        grid-column: 1 / 2;
        grid-row: 1 / span 1;
        margin: 0rem;
      }
      h2 {
        grid-column: 2 / 4;
        grid-row: 1 / span 1;
        margin-bottom: 0rem;
        font-size: 2.5rem;
        align-self: center;
      }
      a {
        padding: 2rem;
        grid-template-columns: 26% 1fr min-content;
        grid-template-rows: 1fr;
      }
    }
    ${SpeakerCard} {
      padding: 2rem;
      border-bottom: none;
      grid-column: 2 / end;
      &:not(:first-of-type) {
        border-top: ${(props) => props.theme.borderStyle};
      }
    }
  `}
  ${media.between("medium", "1055px")`
  ${ApplicationText}, ${BodyOfText} {
      padding-right: 2rem;
      > * {
        max-width: 100%;
      }
    }
    ${BodyOfText} {
      padding-left: 2rem; 
    }
  `}
  ${media.greaterThan("1055px")`
    ${NavigationCard} {
      padding-top: 10%;
    }
    ${ProgramCard}, ${EventCardExt} {
      padding-top: 56.25%;
    }
  `}
  ${media.lessThan("415px")`
  ${ProgramCard}, ${EventCardExt} {
    padding-top: 56.25%;
    height: auto;
    a, div:first-of-type {
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
`}
`
